<template>
    <div class="app-container">
        <!-- 选项卡 -->
        <el-tabs v-model="activeName" type="border-card" @tab-click="clickTab">
            <el-tab-pane label="馆组信息" name="first">
                <div class="library-details" v-if="editLibrary == false">
                    <div class="left">
                        <div class="txt">馆组名称：</div>
                        <div class="txt">所属机构组织全称：</div>
                        <div class="txt">组织机构类型：</div>
                        <!-- <div class="txt">组织机构地址：</div> -->
                        <div class="txt">机构联系人姓名：</div>
                        <div class="txt">机构联系人手机号：</div>
                        <div class="txt">机构联系人职务：</div>
                        <!-- <div class="txt">读者卡号唯一性范围：</div> -->
                    </div>
                    <div class="right">
                        <div class="txt1">{{ details.groupName }}</div>
                        <div class="txt1">{{ details.organizationName }}</div>
                        <div class="txt1">{{ details.organizationType }}</div>
                        <!-- <div class="txt1">{{ details.organizationPosition }}</div> -->
                        <div class="txt1">{{ details.contactName }}</div>
                        <div class="txt1">{{ details.contactPhone }}</div>
                        <div class="txt1">{{ details.contactTitle }}</div>
                        <!-- <div class="txt1">{{ details.uniqueRange }}</div> -->
                    </div>
                    <el-button type="primary" @click="editLibrary = true" style="height: 40px;">编辑</el-button>

                </div>
                <div v-if="editLibrary == true">
                    <div style="float: right;"><el-button type="primary" @click="resetForm1('libraryDetailsList')">保存</el-button></div>

                    <el-form label-position="right" label-width="140px" :model="LabMsg"  :rules="rules"
                        style="display: flex;flex-direction: column;align-items: center;width: 80%;" ref="libraryDetailsList">
                        <el-form-item label="馆组名称：" prop="groupName">
                            <el-input v-model="LabMsg.groupName" style="width: 150%;"></el-input>
                        </el-form-item>
                        <el-form-item label="所属组织机构全称：" prop="organizationName">
                            <el-input v-model="LabMsg.organizationName" style="width: 150%;"></el-input>
                        </el-form-item>
                        <el-form-item label="组织机构类型：" prop="organizationType">
                            <el-input v-model="LabMsg.organizationType" style="width: 150%;"></el-input>
                        </el-form-item>
                        <el-form-item label="机构联系人姓名：" prop="contactName">
                            <el-input v-model="LabMsg.contactName" style="width: 150%;"></el-input>
                        </el-form-item>
                        <el-form-item label="机构联系人手机号：" prop="contactPhone">
                            <el-input v-model="LabMsg.contactPhone" style="width: 150%;"></el-input>
                        </el-form-item>
                        <el-form-item label="机构联系人职务：" prop="contactTitle">
                            <el-input v-model="LabMsg.contactTitle" style="width: 150%;"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="馆组设置" name="second">
                <div class="setting">乐兔阅读设置</div>
                <div style="border-bottom: 1px solid rgba(128, 128, 128, 0.544); margin: 10px 0;"></div>
                <div style="display: flex;align-items: center;">
                    <div class="setting-item">图书展示方式：</div>
                    <el-radio v-model="radio" label="1">按“中图法”分类展示</el-radio>
                    <el-radio v-model="radio" label="2">按图书“标签”展示</el-radio>
                </div>
            </el-tab-pane>
            <el-tab-pane label="分馆信息" name="third">
                <el-form :inline="true" :model="form12">
                    <el-form-item label="分馆名称">
                        <el-input v-model="form12.branchName" placeholder="请输入分馆名称" style="width: 380px;"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="searchBranchName">搜索</el-button>
                    </el-form-item>
                   
                        <el-button style="float: right;" type="primary" icon="el-icon-plus" @click="addBranchName">新增</el-button>
                </el-form>
                <el-tabs v-model="TabactiveName" type="border-card" @tab-click="TabClick">
                    <el-tab-pane label="全部" name="Tabfirst"></el-tab-pane>
                    <el-tab-pane label="已过期" name="Tabsecond"></el-tab-pane>
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column prop="branchName" label="分馆名称" width="180">

                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="180">
                        </el-table-column>
                        <el-table-column prop="expireTime" label="到期时间">
                        </el-table-column>
                        <!-- <el-table-column prop="value" label="通借通还" width="180">
                            <template slot-scope="scope">
                                <el-switch active-color="#409EFF" inactive-color="#909399">
                                </el-switch>
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="isDelete" label="状态" width="180">
                            <template slot-scope="scope">
                                {{ scope.row.isDelete == 0 ? '正常' : '已过期' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="branchHandleEdit(scope.row)">编辑</el-button>
                                <el-button type="text" size="small" @click="branchHandleDelete(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tabs>
                <el-dialog 
                         title="添加分馆"
                         :visible.sync="addLibraryDialogVisible"
                         width="45%"
                         :append-to-body="true">
                    <el-form :model="addLibraryBranch" label-width="80px" inline  :rules="addLibraryBranchRules" ref="addLibraryBranchForm">  
                        <el-form-item label="分馆名称" prop="branch_name">
                            <el-input v-model="addLibraryBranch.branch_name" placeholder="分馆名称"></el-input>
                        </el-form-item>
                        <el-form-item label="分馆类型" prop="branch_type">
                            <el-select v-model="addLibraryBranch.branch_type" clearable >
                                <el-option label="学校馆" value="0"></el-option>
                                <el-option label="社区馆" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="盈利性质" prop="profitable_nature">
                            <el-select v-model="addLibraryBranch.profitable_nature" clearable >
                                <el-option label="公益馆" value="0"></el-option>
                                <el-option label="盈利馆" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="分馆地址"> -->
                            <!-- <el-input v-model="addLibraryBranch.branch_position" placeholder="分馆地址"></el-input> -->
                            <div style="display: flex;">
                            <el-form-item label="分馆地址:"   prop="branch_position">
                                <el-cascader size="large" :options="optionLibraryCity" v-model="addLibraryBranch.branch_position"
                                    :props="{ checkStrictly: true }"  @change="addhandleChange" >
                                </el-cascader>
                            </el-form-item>
                            <el-input style="margin-left: 20px; width: 200px;" v-model="addLibraryBranch.contactAddress"
                                placeholder="请输入详细地址"></el-input>
                        </div>
                        <!-- </el-form-item> -->
                        <el-form-item label="分馆电话" prop="phone">
                            <el-input v-model="addLibraryBranch.phone" maxlength="11" placeholder="分馆电话"> </el-input>
                        </el-form-item>
                        <el-form-item label="分馆介绍" prop="branch_intro">
                            <el-input v-model="addLibraryBranch.branch_intro" placeholder="分馆介绍" type="textarea"> </el-input>
                        </el-form-item>
                       
                   </el-form>
                   <span slot="footer" class="dialog-footer">
                        <el-button @click="addLibraryDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="addLibraryBranchForm('addLibraryBranchForm')">确 定</el-button>
                    </span>
                </el-dialog>
                <el-dialog
                    :title="libraryBranchData.branchName"
                    :visible.sync="branchDialogVisible"
                    width="65%"
                    append-to-body
                    :before-close="branchHandleClose">
                    <el-form :model="libraryBranchData"  :inline="true" label-width="110px" ref="libraryDetailsList" :rules="branchrules">
                            <el-form-item label="馆组名称：" prop="branchName">
                                <el-input v-model="libraryBranchData.branchName" placeholder="请输入馆组名称"></el-input>
                            </el-form-item>
                           
                            <el-form-item label="分馆类型：" prop="branchType">
                                <!-- <el-input v-model="libraryDetailsList.branchType" placeholder="请输入分馆类型"></el-input> -->
                                <el-radio v-model="libraryBranchData.branchType" label="0">学校馆</el-radio>
                                <el-radio v-model="libraryBranchData.branchType" label="1">社区馆</el-radio>
                            </el-form-item>
                            <el-form-item label="分馆地址：" prop="branchPosition">
                                <el-input v-model="libraryBranchData.branchPosition" placeholder="请输入分馆地址"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话：" prop="phone">
                                <el-input v-model="libraryBranchData.phone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                            <el-form-item label="营利性质：" prop="profitableNature">
                                <!-- <el-input v-model="libraryDetailsList.profitableNature" placeholder="请输入营利性质"></el-input> -->
                                <el-radio v-model="libraryBranchData.profitableNature" label="0">公益馆</el-radio>
                                <el-radio v-model="libraryBranchData.profitableNature" label="1">商业馆</el-radio>
                            </el-form-item>
                            
                            <el-form-item label="到期时间：" prop="expireTime">
                                <el-date-picker
                                    v-model="libraryBranchData.expireTime"
                                    type="datetime"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 206px;"
                                ></el-date-picker>
                            </el-form-item>
                            <el-form-item label="分馆介绍：" prop="branchIntro">
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入分馆介绍"
                                    v-model="libraryBranchData.branchIntro"
                                ></el-input>
                            </el-form-item>
                           
                        </el-form>

                    <span slot="footer" class="dialog-footer">
                        <el-button @click="branchDialogVisible = false">取 消</el-button>
                        <!-- <el-button type="primary" @click="branchDialogVisible = false">确 定</el-button> -->
                        <el-button type="primary" @click="resetForm12('libraryDetailsList')">确 定</el-button>
                    </span>
                </el-dialog>

                   <el-pagination
                        @size-change="handleSizeChange1"
                        @current-change="handleCurrentChange1"
                        :current-page="page.current"
                        :page-sizes="[2, 5, 10, 50, 100]"
                        :page-size="10"
                        :page-count="page.pageTemp"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                    </el-pagination>
            </el-tab-pane>
            <!-- ssssss -->
            <el-tab-pane label="安全设置" name="sixth" v-if="false">
                <!-- 功能按钮组 -->
                <el-row :gutter="20" style="margin-bottom: 7px">
                    <el-col :span="1.5">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain @click="handleAdd">新建密钥</el-button>
                        <el-button v-if="exists != 1" type="primary" size="small" icon="el-icon-plus" plain
                            @click="handleAlter('设置二级密码')">设置二级密码</el-button>
                        <!-- <el-button v-else type="primary" size="small" icon="el-icon-plus" plain
                            @click="handleAlter('修改二级密码')">修改二级密码</el-button> -->
                    </el-col>
                </el-row>
                <!-- 表格数据 -->
                <el-table ref="table" :data="mouseregisterData">
                    <el-table-column prop="appName" label="APPName" />
                    <el-table-column prop="appKey" label="app_key" />
                    <el-table-column prop="secret" label="secret" />
                    <el-table-column prop="status" label="状态" />
                    <el-table-column label="操作" width="180px">
                        <template slot-scope="scope">
                            <el-button size="mini" v-if="scope.row.status == '禁用'" icon="el-icon-edit" type="primary" plain
                                @click="handleChangeStatus(scope.row, '启用')">启用</el-button>
                            <el-button v-else size="mini" icon="el-icon-delete" type="danger" plain
                                @click="handleChangeStatus(scope.row, '禁用')">禁用</el-button>
                            <el-button size="mini" icon="el-icon-delete" type="danger" plain
                                @click="handleDelete(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page.current" :page-size="page.size" :page-sizes="[2, 5, 10, 20, 100]"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                </el-pagination>

                <!-- 编辑或添加对话框 -->
                <el-dialog :title="dialogTitle" :modal="false" :visible.sync="dialogFormVisible" width="42%" center
                    :append-to-body="true">
                    <el-form ref="dialogForm" :model="dialogForm" center>
                        <el-form-item v-if="dialogType == 'add'" prop="appName">
                            <el-input v-model="dialogForm.appName" autocomplete="off" placeholder="请输入appName" />
                        </el-form-item>
                        <el-form-item v-if="dialogType == 'delete' || dialogType == 'changeStatus'" prop="appKey">
                            <el-input v-model="rowObj.appKey" autocomplete="off" placeholder="请输入appKey" />
                        </el-form-item>
                        <el-form-item v-if="dialogType != 'add'" prop="secondPassword">
                            <el-input v-model="dialogForm.secondPassword" autocomplete="off" placeholder="请输入二级密码" />
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="cancelDialog">取 消</el-button>
                        <el-button type="primary" @click="submitDialog">确 定</el-button>
                    </div>
                </el-dialog>

            </el-tab-pane>
        </el-tabs>
        <!-- <div class="bottom" v-if="activeName == 'first' && editFlag == false">
            <el-button class="btn" type="primary" size="small" @click="editMsg">编辑馆组信息</el-button>
        </div>
        <div class="bottom" v-if="activeName == 'first' && editFlag == true">
            <el-button class="btn" type="success" size="small" @click="editMsgConfirm">确定编辑</el-button>
            <el-button class="btn" size="small" @click="editMsgReturn">返回</el-button>
        </div> -->
    </div>
</template>
   
<script>
import { regionData, codeToText, TextToCode } from 'element-china-area-data'
import { listLibrary, AdmindetailsData, libraryEdit, listLibraryOne,libraryEditOne,addLibrary } from '@/api/library'
import { oauthList, oauthAdd, oauthDelete, oauthExists, oauthChangeStatus, oauthSetSecond } from '@/api/mouseregister'
import SearchBak from '@/components/SearchBak'

export default {
    components: {
        SearchBak
    },
    data() {
        return {
            //添加分馆弹窗
            optionLibraryCity:regionData,
            addLibraryDialogVisible:false,
            addLibraryBranch:{},
            //分馆校验规则
            addLibraryBranchRules: {
                branch_name: [
                    { required: true, message: '请输入分馆名称', trigger: 'blur' },
                    { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                ],
                branch_type: [{ required: true, message: '请选择分馆类型', trigger: 'change' }],
                profitable_nature: [{ required: true, message: '请选择盈利性质', trigger: 'change' }],
               // branch_position: [{ required: true, message: '请选择分馆地址', trigger: 'change' }],
                phone: [{ required: true, message: '请输入分馆电话', trigger: 'blur' },{ min: 11, max: 11, message: '长度 为11位', trigger: 'blur' }, { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' }],
               // branch_intro: [{ required: true, message: '请输入分馆介绍', trigger: 'blur' }]
            },  
            branchrules: {
        branchName: [
          { required: true, message: '馆组名称不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符之间', trigger: 'blur' },
        ],
        groupName: [
          { required: true, message: '所属馆组不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符之间', trigger: 'blur' },
        ],
        branchType: [
          { required: true, message: '分馆类型不能为空', trigger: 'blur' },
        ],
        branchPosition: [
          { required: true, message: '分馆地址不能为空', trigger: 'blur' },
          { min: 5, max: 100, message: '长度在 5 到 100 个字符之间', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' },
        ],
        profitableNature: [
          { required: true, message: '营利性质不能为空', trigger: 'blur' },
        ],
        createTime: [
          {  required: true, message: '请选择创建时间', trigger: 'change' },
        ],
        expireTime: [
          {  required: true, message: '请选择到期时间', trigger: 'change' },
        ],
        branchIntro: [
          { required: true, message: '分馆介绍不能为空', trigger: 'blur' },
          { min: 10, max: 500, message: '长度在 10 到 500 个字符之间', trigger: 'blur' },
        ],
      },
            //编辑图书馆 分馆对象
            libraryBranchData: 
            {
                id: undefined,
                aid: undefined,
                branchName: "",
                branchIntro: "",
                branchType: "",
                profitableNature: "",
                branchPosition: "",
                availableNum: undefined,
                availableTime: undefined,
                phone: "",
                createTime: "",
                updateTime: "",
                expireTime: "",
                isDelete: ""
            },
            //控制修改分馆弹窗
            branchDialogVisible: false,
            // 搜索条件 搜索分管
            form12:{
                branchName: '',

            },
            rules: {
                groupName: [
          { required: true, message: '馆组名称不能为空', trigger: ['blur'] },
          { min: 2, max: 30, message: '馆组名称长度应在2到30个字符之间', trigger: ['blur'] }
        ],
        organizationName: [
          { required: true, message: '所属组织机构全称不能为空', trigger: ['blur'] },
          { min: 2, max: 60, message: '全称长度应在2到60个字符之间', trigger: ['blur'] }
        ],
        organizationType: [
          { required: true, message: '组织机构类型不能为空', trigger: ['blur'] },
          { min: 2, max: 30, message: '类型长度应在2到30个字符之间', trigger: ['blur'] }
        ],
        contactName: [
          { required: true, message: '联系人姓名不能为空', trigger: ['blur'] },
          { min: 2, max: 30, message: '姓名长度应在2到30个字符之间', trigger: ['blur'] }
        ],
        contactPhone: [
          { required: true, message: '联系人手机号不能为空', trigger: ['blur'] },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: ['blur'] }
        ],
        contactTitle: [
          { required: true, message: '联系人职务不能为空', trigger: ['blur'] },
          { min: 2, max: 30, message: '职务长度应在2到30个字符之间', trigger: ['blur'] }
        ]
      },
            editLibrary:false,
            // =============馆组信息===========
            editFlag: false,
            LabMsg: [],

            // =============分馆信息===========
            formInline: {
                name: ""
            },
            // 切换Tab
            TabactiveName: "Tabfirst",
            // 当前时间
            nowDate: "",

            // 总馆详情
            details: {},
            // 查询表单数据
            queryForm: {},
            // 表格数据
            tableData: [],
            mouseregisterData: [],
            // 多选数据
            multipleSelection: [],
            // 对话框类型[add|edit]
            dialogType: '',
            //操作数据
            rowObj: {},
            // 对话框
            dialogTitle: undefined,
            // 对话框是否可见
            dialogFormVisible: false,
            // 对话框数据
            dialogForm: {},
            // 判断是否有二级密码
            exists: undefined,
            // 分页数据
            page: {
                // page: 1, // 当前页
                // size: 10, // 每页显示数
                // total: undefined // 总计
                current: 1,
                pageTemp: 1,
                size: 10,
                total: undefined
            },

            radio: '1',
            activeName: "first",
            // 开关
            value: true,
            // 对话框
            dialogFormVisible1: false,
            // 时间
            value1: ''
        }
    },
    created() {
        this.Admindetails()
        this.getList()
        this.mouseregisterList()
        this.getNowDay()
    },
    methods: {
        //============分管修改=============
        //添加分馆
        addhandleChange(value){
            this.getCodeToText(value);
        },
        getCodeToText(codeArray) {
      let area = "";
      switch (codeArray.length) {
        case 1:
          area += codeToText[codeArray[0]];
          break;
        case 2:
          area += codeToText[codeArray[0]] + "/" + codeToText[codeArray[1]];
          break;
        case 3:
          area +=
            codeToText[codeArray[0]] +
            "/" +
            codeToText[codeArray[1]] +
            "/" +
            codeToText[codeArray[2]];
          break;
        default:
          break;
      }
      this.addLibraryBranch.branch_position = area;
      console.log(area);
    },
        addBranchName(){
            this.addLibraryDialogVisible = true
        },
        addLibraryBranchForm(addLibraryBranchForm){
            this.addLibraryBranch.branch_position = this.addLibraryBranch.branch_position +"/"+ this.addLibraryBranch.contactAddress != "" ? this.addLibraryBranch.contactAddress : ""
            console.log(this.addLibraryBranch)
            //let name = codeToText("cesi"="13" )
           //console.log(name)
           this.$refs[addLibraryBranchForm].validate((valid,value)=>{
                if(valid){
                   let params = {...this.addLibraryBranch}
                    addLibrary(params).then(res => {
                        console.log(res)
                        if(res.code == 200){
                            alert("添加成功");
                            this.addLibraryDialogVisible = false
                            const queryParams = { page: this.page.current, size: this.page.size }
                            listLibrary(queryParams).then(({ data }) => {
                                this.tableData = data.libraryBranchData
                                this.page.total = data.total
                                this.page.current = data.current
                                this.page.pageTemp = data.pageNum
                            })
                           // this.backToPage();
                        }else{
                            this.$message.error(res.data.message);
                        }
                    })
                }else{
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                }
            })
        },
        //编辑分馆
        resetForm12(libraryDetailsList){
            
            
            this.$refs[libraryDetailsList].validate((valid, value) => {
                 if(valid){
                   
                     const params = {...this.libraryBranchData}
                     libraryEditOne(params).then((res)=>{
                         if(res.code == '200'){
                             this.$message.success("修改成功")
                             this.getList()
                             this.branchDialogVisible = false
                         }else{
                             alert("修改失败")
                             this.branchDialogVisible = true
                         }
                     })
                 }else{
                    // console.log('11111')
                     Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                 }
 
            })
             
         },
        //el-dialog弹出框
        branchHandleClose(){
            this.branchDialogVisible = false

        },
        //修改
        branchHandleEdit(row){
           // console.log(row);
           this.libraryBranchData = row
           this.libraryBranchData.expireTime = row.expireTime+' 12:00:00'
           this.branchDialogVisible = true


        },
        //删除
        branchHandleDelete(row){
            alert('功能还没有完善哦')
        },
         // 每页显示数改变
        handleSizeChange1(val) {
        //  console.log(`每页 ${val} 条`)
        this.page.size = val
       
       // console.log(this.form12.branchName)
        if(this.form12.branchName != ''){
            console.log('sdsds')
            listLibraryOne(this.form12).then(({ data }) => {
                this.tableData = data.libraryBranchData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageTemp = data.pageNum
            })
        }else {
            // this.getList()
            const queryParams = { page: this.page.current, size: this.page.size }
            listLibrary(queryParams).then(({ data }) => {
                this.tableData = data.libraryBranchData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageTemp = data.pageNum
            })
        }
        
        },
        // 当前页改变
        handleCurrentChange1(val) {
        // console.log(`当前页: ${val}`)
        this.page.current = val
        if(this.form12.branchName != ''){
            listLibraryOne(this.form12).then(({ data }) => {
                this.tableData = data.libraryBranchData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageTemp = data.pageNum
            })
        }else {
            //this.getList()
            const queryParams = { page: this.page.current, size: this.page.size }
            listLibrary(queryParams).then(({ data }) => {
                this.tableData = data.libraryBranchData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageTemp = data.pageNum
            })
        }
        },
        //搜索分管
        searchBranchName(){
           // console.log(this.form12.branchName)
           const queryParams = this.form12 
           listLibraryOne(queryParams).then(({ data }) => {
                this.tableData = data.libraryBranchData
                
            })
        },
        resetForm1(libraryDetailsList){
            
            //console.log(this.$refs[libraryDetailsList]) 
            this.$refs[libraryDetailsList].validate((valid, value) => {
                 if(valid){
                     //发送请求
                    
                     const params = {...this.LabMsg}
                     libraryEdit(params).then((res)=>{
                         if(res.code == '200'){
                             this.$message.success("修改成功")
                             this.Admindetails()
                             this.editLibrary = false
                         }else{
                             alert("修改失败")
                             this.editLibrary = true
                         }
                     })
                 }else{
                    // console.log('11111')
                     Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                 }
 
            })
             
         },


        // ================馆组信息=============
        editMsg() {
            this.editFlag = true
        },
        editMsgConfirm() {
            let queryData = { ...this.LabMsg };
            libraryEdit(queryData).then(res => {
                if (res.code == "200") {
                    alert("修改成功");
                    this.Admindetails()
                    this.editFlag=false
                }
            })
        },
        editMsgReturn(){
            this.editFlag= false;
        },


        //============== 分馆API =============
        // 馆组信息
        Admindetails() {
            AdmindetailsData().then(({ data }) => {
                this.details = data;
                // 需要修改的馆组信息
                this.LabMsg = data;
                console.log(data);
            })
        },
        // 点击分页 回到全部触发查询所有 已过期进行筛选
        TabClick(val) {
            this.TabactiveName = val.name;
            if (this.TabactiveName == "Tabfirst") {
                this.getList();
            }
            // 分馆信息中 点击到已过期 过滤筛选
            else {
                this.tableData = this.tableData.filter(item => {
                    return new Date(item.expireTime) < new Date(this.nowDate)
                })
            }
        },
        // 查询分馆列表
        getList() {
            const queryParams = { ...this.queryForm, page: 1, size: 10 }
            listLibrary(queryParams).then(({ data }) => {
                this.tableData = data.libraryBranchData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageTemp = data.pageNum
            })
        },
        // 获得当前时间
        getNowDay() {
            const previousDate = new Date(new Date());
            previousDate.setDate(previousDate.getDate() - 1);
            // 格式化日期
            const year = previousDate.getFullYear();
            const month = String(previousDate.getMonth() + 1).padStart(2, '0');
            const day = String(previousDate.getDate()).padStart(2, '0');
            this.nowDate = (`${year}-${month}-${day}`)
            console.log(this.nowDate);
        },

        // 查询密钥列表
        mouseregisterList() {
            const queryParams = { ...this.queryForm, page: this.page.current, size: this.page.size }
            oauthList(queryParams).then(({ data }) => {
                this.mouseregisterData = data
                // this.page.total = data.total
            })
        },
        // 查询是否有二级密码
        clickTab({ index }) {
            if (index == 2) {
                oauthExists().then((res) => {
                    if (res.code == 200) {
                        this.exists = res.data.exists
                        //提示用户设置二级密码
                        if (this.exists != 1) {
                            this.$confirm('您还没有设置二级密码，请先设置二级密码再进行其他操作', '提示', {
                                confirmButtonText: '确定',
                                type: 'warning'
                            })
                        }
                    }
                })
            }
        },
        //============== 查询表单 =================
        // 查询表单提交
        querySubmit() {
            this.getList()
            this.mouseregisterList()
        },
        //============== 表格 ==================
        // 多选改变
        handleSelectionChange(val) {
            this.multipleSelection = val
            this.editDisabled = !(val.length === 1)
            this.removeDisabled = !(val.length > 0)
        },
        // 添加二级密码
        handleAdd() {
            // 清除对话框表单数据
            this.dialogForm = {}
            this.dialogType = 'add'
            this.dialogTitle = '请输入APPName'
            this.showDialog()
        },
        // 修改二级密码
        handleAlter(title) {
            this.dialogForm = {}
            this.dialogType = 'edit'
            this.dialogTitle = title
            this.showDialog()
        },
        handleDelete(row) {
            this.dialogForm = {}
            this.rowObj = row //这行数据
            this.dialogType = 'delete'
            this.dialogTitle = '刪除app_Key和secret'
            this.showDialog()
        },
        handleChangeStatus(row, title) {
            this.dialogForm = {}
            this.rowObj = row //这行数据
            this.dialogType = 'changeStatus'
            this.dialogTitle = title + 'app_Key和secret'
            this.showDialog()
        },
        //=============== 密钥 ==================
        // 添加密钥
        addData() {
            oauthAdd(this.dialogForm).then((res) => {
                if (res.code == 200) {
                    this.msgSuccess('添加成功')
                    this.mouseregisterList()
                }
            })
        },
        // 修改二级密码
        updateData() {
            oauthSetSecond(this.dialogForm).then((res) => {
                if (res.code == 200) {
                    this.msgSuccess(res.data.result)
                    this.mouseregisterList()
                }
            })
        },
        // 删除密钥
        deleteData() {
            let dialogForm = { ...this.dialogForm, appKey: this.rowObj.appKey }
            oauthDelete(dialogForm).then((res) => {
                if (res.code == 200) {
                    this.msgSuccess('删除成功')
                    this.mouseregisterList()
                }
            })
        },
        // 禁用和启用
        changeStatusData() {
            let dialogForm = { ...this.dialogForm, appKey: this.rowObj.appKey }
            oauthChangeStatus(dialogForm).then((res) => {
                if (res.code == 200) {
                    this.msgSuccess(res.data.status + '成功')
                    this.mouseregisterList()
                }
            })
        },
        //============== 添加或编辑对话框 ===================
        // 显示对话框
        showDialog() {
            this.dialogFormVisible = true
        },
        // 对话框取消
        cancelDialog() {
            this.dialogFormVisible = false
        },
        // 对话框提交
        submitDialog() {
            // console.log(this.dialogForm)
            if (this.dialogType == 'add') {
                // console.log('执行添加二级密码')
                this.addData()
            } else if (this.dialogType == 'edit') {
                // console.log('执行修改二级密码')
                this.updateData()
            } else if (this.dialogType == 'delete') {
                // console.log('执行删除二级密码')
                this.deleteData()
            } else {
                // console.log('启/禁用')
                this.changeStatusData()
            }
            this.dialogFormVisible = false
        },
        //=============== 分页 ==================
        // 每页显示数改变
        handleSizeChange(val) {
            this.page.size = val
            this.getList()
        },
        // 当前页改变
        handleCurrentChange(val) {
            this.page.page = val
            this.getList()
        },

        //================= 假期设置 ============
        // 点击展开提示警告信息
        open() {
            const h = this.$createElement;
            this.$notify({
                title: '说明',
                message: h('o', { style: 'color: gray' }, '读者所借图书在假期间到期或者跨越假期的，会相应的延长到期时间。1、在假期开始前借书、假期中间或假期结束后到期的，到期时间会延长的天数：结束日期-开始日期+1。2、在假期中间借书、假期中间或假期结束后到期的，到期时间会延长的天数：结束日期-借出日期+1')
            });
        },
    }
}

</script>
  
<style scoped>
.library-details {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-template-columns:180px 180px;
    grid-column-gap: 20px;
    position: relative; */
}

.library-details .left {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.library-details .right {
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.txt {
    color: #858585;
    line-height: 2.5rem;
    font-size: 14px;
    display: flex;
    text-align: right;
    margin-right: 20px;
}

.txt1 {
    line-height: 2.5rem;
    font-size: 14px;
    text-align: right;
    min-width: 150px;
}

.setting {
    font-weight: 700;
    font-size: 16px;
    color: #17233d;
}

.setting-item {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    font-size: 14px;
    margin-right: 20px;
}

.bottom {
    position: fixed;
    bottom: 0;
    padding: 15px 30px;
    border: 1px solid #DCDFE6;
    background-color: #FFF;
    border-radius: 5px;
    width: 79%;
}
</style>